<template>
  <div class="main-wrapper">
    <!-- Page Section Start -->
    <div class="page-section section section-padding section-space--inner--60">
      <div class="container">
        <div class="row row-30 mb-40">
          <div
            class="col-xl-9 col-lg-8 col-12 order-1 order-lg-2"
            style="margin-bottom: 40px"
          >
            <div class="row justify-content-end" style="margin-bottom: 40px">
              <div class="col-4">
                <div class="input-group">
                  <input type="text" v-model="search" class="form-control" />
                  <div class="input-group-prepend">
                    <button
                      @click.prevent="searchProduct()"
                      class="btn btn-primary"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- SHOW DATA -->
            <!-- CARI -->
            <div v-if="showsearch == true">
              <div class="row">
                <div
                  v-for="cari in caris"
                  v-bind:key="cari.nama_produk"
                  class="col-xl-4 col-md-6 col-12"
                >
                  <div class="product-item">
                    <div class="product-inner">
                      <div class="image">
                        <img
                          :src="cari.url_gambar_produk"
                          alt="Image Not Found"
                        />

                        <div class="image-overlay">
                          <div class="action-buttons">
                            <router-link :to="/detil-product/ + cari.id_produk">
                              <a href="">
                                <button>Lihat Produk</button>
                              </a></router-link
                            >
                          </div>
                        </div>
                      </div>

                      <div class="content">
                        <div class="content-left">
                          <p class="title">{{ cari.nama_produk }}</p>
                          <p>
                            Rp
                            <span>{{ formatPrice(cari.harga_produk) }}</span>
                          </p>
                          <h5 class="jenis">Jenis: {{ cari.jenis_produk }}</h5>
                          <h5 class="tipe">Merk: {{ cari.merk_produk }}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="showsearch == false">
              <!-- show all data -->
              <div class="row">
                <div
                  v-for="produk in produks"
                  :key="produk.id_produk"
                  class="col-xl-4 col-md-6 col-12"
                  style="margin-bottom: 20px"
                >
                  <div class="product-item">
                    <div class="product-inner">
                      <div class="image">
                        <img
                          :src="produk.url_gambar_produk"
                          alt="Image Not Found"
                        />

                        <div class="image-overlay">
                          <div class="action-buttons">
                            <router-link
                              :to="/detil-product/ + produk.id_produk"
                            >
                              <a href="">
                                <button>Lihat Produk</button>
                              </a></router-link
                            >
                          </div>
                        </div>
                      </div>

                      <div class="content">
                        <div class="content-left">
                          <p class="title">{{ produk.nama_produk }}</p>
                          <p>
                            Rp
                            <span>{{ formatPrice(produk.harga_produk) }}</span>
                          </p>
                          <h5 class="jenis">
                            Jenis: {{ produk.jenis_produk }}
                          </h5>
                          <h5 class="tipe">Merk: {{ produk.merk_produk }}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-12">
                <ul class="page-pagination">
                  <li>
                    <a href="#"><i class="fa fa-angle-left"></i></a>
                  </li>
                  <li class="active"><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li>
                    <a href="#"><i class="fa fa-angle-right"></i></a>
                  </li>
                </ul>
              </div> -->
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-12 order-2 order-lg-1">
            <!-- <div class="sidebar">
              <h4 class="sidebar-title">Kategori</h4>
              <ul class="sidebar-list">
                <li><a href="#">Ban</a></li>
                <li><a href="#">Sparepart Roda 2</a></li>
                <li><a href="#">Sparepart Roda 4</a></li>
                <li><a href="#">Power Tools</a></li>
              </ul>
            </div> -->

            <div class="sidebar">
              <a href="">
                <h4 class="sidebar-title">Ban</h4>
              </a>

              <ul class="sidebar-list">
                <li>
                  <router-link :to="/product/ + 'mizzle'">
                    <a href="#">Mizzle</a></router-link
                  >
                </li>
                <li><a href="#">Swallow</a></li>
                <li><a href="#">IRC</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Section End -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      produks: [],
      search: "",
      filter: "",
      showsearch: false,
      showfilter: false,
      caris: [],
    };
  },

  methods: {
    getData() {
      axios
        .get(
          "https://admin.putraramayana.com/api/produks/getbymerk/" +
            this.$route.params.merk_produk
        )
        .then((response) => {
          this.produks = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style></style>
