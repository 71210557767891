<template>
  <div class="main-container">
    <Header />
    <ProductFilter />
    <Footer />
    <OffCanvasMobileMenu />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import ProductFilter from "../components/ProductByMerk.vue";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  components: {
    Header,
    ProductFilter,
    Footer,
    OffCanvasMobileMenu,
  },
  metaInfo: {
    title: "Putra Ramayana - Detail Produk",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>
